import React, {useContext} from "react";
import "./Contact.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import { contactInfo } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

export default function Contact() {
  const { isDark } = useContext(StyleContext);
  return (
      <Fade bottom duration={1000} distance="20px">
        <div className="main contact-margin-top" id="contact">
          <div className="contact-div-main">
            <div className="contact-header">
              <h1 className="heading contact-title">{contactInfo.title}</h1>
              <p
                  className={
                    isDark
                        ? "dark-mode contact-subtitle"
                        : "subTitle contact-subtitle"
                  }
              >
                {contactInfo.subtitle}
              </p>

              <div
                  className={
                    isDark ? "dark-mode contact-text-div" : "contact-text-div"
                  }
              >
                <a className="contact-detail-desc" href={ contactInfo.desc }>
                  {contactInfo.desc}
                </a>
                <br/>
                <br/>
                <a
                    className="contact-detail-email"
                    href={"mailto:" + contactInfo.email_address}
                >
                  {contactInfo.email_address}
                </a>
                <br/>
                <br/>
                <SocialMedia/>
              </div>
            </div>
            <div className="contact-image-div">
              <img alt="Kilian MAYANS" src={require(`../../assets/images/${ isDark ? "MAYANS_Kilian_+Z.jpg" : "MAYANS_Kilian_+Z.jpg" }`)}/>
            </div>
          </div>
        </div>
      </Fade>
  );
}
